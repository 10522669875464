import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const heroCarousel = (function(){

	let my = {};

	my.init = function () {
		if (document.getElementById('home_hero_caro')) {
			const options = {
				element: document.getElementById('home_hero_caro'),
				images: window.home_slide_images,
				imagesAsBackgrounds: true,
				showDots: true,
				useChevrons: false
			};
			AutomatitCarousel(options);
		}
	};

	return my;
})();