export const stickyContainer = (function(){

	let my = {};

	function _stickyContainer() {
		const contentPage = document.querySelector('.content ');
		const ctaContainer = document.querySelector('.internal_sidebar');
		
		window.addEventListener('scroll', () => {
			
			if(window.outerWidth > 1200 && contentPage && ctaContainer) {
				let displayOffset = 0;
				let x = window.scrollY - contentPage.offsetTop - displayOffset;
				let min = 0;
				let max = contentPage.offsetHeight - ctaContainer.offsetHeight;
				if(x < min) x = min;
				if(x > max) x = max;
				ctaContainer.style.top = x + 'px';
			}
		});
	}

	my.init = function(){
		_stickyContainer();
	};

	return my;
})();