import { contactSubmit } from './contact.js';
import { navToggle } from './header.js';
import { heroCarousel } from './home.js';
import { reviewsCaro } from './reviews.js';
import { facilityCarousel, facilityFilter, formCards } from './facility.js';
import { stickyContainer } from './stickyContainer.js';

if (document.getElementById('contact_form')) {
	contactSubmit();
}

navToggle.init();

if(document.querySelector('#home_hero_caro')) {
	heroCarousel.init();
}

if(document.querySelector('#reviews_caro')){
	reviewsCaro.init();
}

if(document.querySelector('#facility_caro')){
	facilityCarousel.init();
}

if(document.querySelector('#facility_filters')){
	facilityFilter.init();
}

if(document.querySelector('#facility_forms')){
	formCards.init();
}

if(document.querySelector('.internal_sidebar')){
	stickyContainer.init();
}

if(document.querySelector('.overlay_options')){
	
	/* eslint-disable */
	$('.rates_button').click(function(e) {
		$('.rates_overlay').toggleClass("open");
		//console.log(document.querySelectorAll('.overlay_options .option_wrap').length)
		var button = e.currentTarget;
	
		if (!button) {
			return;
		}
	
		const scrollHeight = $(window).scrollTop();
	
		var groupIdentifier = button.getAttribute('data-group');
	
		var overlay = $(".overlay_options[data-group='" + groupIdentifier + "']");
	
		var overlayHeight = overlay.height();
	
		//var overlaySelHeight = $('.overlay_selection').height();
	
		if (overlay.length) {
			//>> activate the overlay
			overlay.toggleClass("open");
		} else {
			toRatesHandler(button, false);
		}
	});
	
	var toRatesHandler = (el, overlay) => {
	
		$('#back_button').click(function() {
	
			if (overlay == false) {
				$('.rates_overlay').removeClass("open");
				//$('.overlay_selection').removeClass("open");
			} else {
				//$('.overlay_selection').removeClass("open");
				$(overlay).toggleClass('open');
				//$('.rates_overlay').addClass("open");
				overlay = false;
			}
		});
		
		if (el.hasAttribute('data-inquiry-only')) {
			location.href = el.getAttribute('data-inquire');
		} else {
			var rentUrl = el.getAttribute('data-rent');
			$('.rent_overlay').attr("href", rentUrl);
			$('.overlay_options.open').toggleClass("open");
			//if($('.overlay_selection').length){
				//$('.rent_overlay').attr("href", rentUrl);
				$('.rates_overlay').toggleClass("open");
				window.open(rentUrl,'_self');
			//}
			//$('.overlay_selection').toggleClass("open");
		}
	}
	
	$('.to_rates').click(function(e) {
		const openOverlay = $('.overlay_options.open');
		toRatesHandler(e.currentTarget, openOverlay);
		//$('.overlay_selection').addClass('open');
	});
	
	//>> close the rates if the overlay behind it is clicked
	$('.rates_overlay').click(function() {
		$('.rates_overlay').removeClass("open");
		$('.overlay_options').removeClass("open");
		//$('.overlay_selection').removeClass("open");
	});
	
	//>> close rates overlay if "X" is clicked
	$('.overlay_close').click(function() {
		$('.rates_overlay').removeClass("open");
		$('.overlay_options').removeClass("open");
		//$('.overlay_selection').removeClass("open");
	});
}
