export const navToggle = (function(){
	let my = {};

	function _addDoubleClick() {
		[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
			el.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if (!el.hasAttribute('clicked')) {
						e.preventDefault();
						el.setAttribute('clicked', true);
					}
				}
			});
		});
	}

	function _mobileToggle() {
		document.getElementById('site_nav_toggle').addEventListener('click', function(e){
			let clickedButton = e.currentTarget;
			let nav = document.getElementById('header_nav');

			clickedButton.toggleAttribute('open');
			nav.toggleAttribute('open');
		});
	}

	function _navDrop() {
		const navItems = document.querySelectorAll('.nav_item');

		const removeActive = (array) => {
			array.forEach(arrayItem => {
				arrayItem.classList.remove('active');
			});
		};
		document.querySelectorAll('.nav_item').forEach(item => {
			item.addEventListener('click', () => {
				if (item.classList.contains('active') === false && item.querySelector('.has_drop')) {
					removeActive(navItems);
					item.classList.add('active');
				} else {
					item.classList.remove('active');
				}
			});
		});
	}

	my.init = function(){
		_addDoubleClick();
		_mobileToggle();
		_navDrop();
	};

	return my;
})();