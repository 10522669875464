import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const facilityCarousel = (function(){

	let my = {};

	my.init = function () {
		if (document.getElementById('facility_caro')) {
			let interval = 5000;
			if(window.facility_slide_images.length === 1){
				interval = false;
			}
			const options = {
				element: document.getElementById('facility_caro'),
				images: window.facility_slide_images,
				imagesAsBackgrounds: true,
				intervalTiming: interval,
				showDots: true,
				useChevrons: false
			};
			AutomatitCarousel(options);
		}
	};

	return my;
})();

export const facilityFilter = (function(){
	let my = {};

	function _facilityFilter() {

		const filters = document.querySelectorAll('.facility_filters_buttons .filter');
		const cards = document.querySelectorAll('.unit_card');
		const noUnits = document.querySelector('#no_units');
		document.querySelector('.facility_filters_buttons').addEventListener('click', e => {
			const clickedFilter = e.target.closest('button');
			filters.forEach(i => {
				if(i === clickedFilter){
					clickedFilter.toggleAttribute('active');
				} else {
					i.removeAttribute('active');
				}
			});
			let unitShown = 0;
			if(clickedFilter) {
				cards.forEach(i => {
					i.setAttribute('hide', true);
					if((clickedFilter.hasAttribute('active'))) {
						if((i.getAttribute('filter') === clickedFilter.getAttribute('filter'))) {
							unitShown++;
							i.setAttribute('hide', false);
						}
						if(unitShown === 0){
							noUnits.style.display = 'block';
						}else {
							noUnits.style.display = 'none';
						}
					} else {
						i.setAttribute('hide', false);
						noUnits.style.display = 'none';
					}
				});
			}
		});
	}

	my.init = function(){
		_facilityFilter();
	};

	return my;
})();

export const formCards = (function(){

	let my = {};

	function _formCards() {
		const formCards = document.querySelectorAll('.form');
        
		formCards.forEach(formCard => {
			formCard.addEventListener('click', (e) => {
				let clickedCard = e.currentTarget;
				let clickedFormLink = clickedCard.getAttribute('data-link');
				console.log(clickedFormLink);
				window.open(clickedFormLink,'_blank');
			});
		});
	}

	my.init = function(){
		_formCards();
	};

	return my;
})();