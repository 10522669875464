import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const reviewsCaro = (function(){

	let my = {};

	my.init = function () {
		const caro = document.querySelector('#reviews_caro');

		const options = {
			element: caro,
			intervalTiming: false,
			useChevrons: false,
			showDots: true,
		};
		
		window.reviewsCaro = AutomatitCarousel(options);
	};

	return my;
})();